<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {
      logged: (this.$store.state && this.$store.state.logged) || false
    };
  },
  mounted() {
    if (this.logged && this.$store.state.accessToken) {
      this.logout();
    } else {
      this.$router.push({ path: "/" }).catch(() => {
        // do nothing
      });
    }
  },
  methods: {
    logout() {
      this.$store.commit('logout');
      this.$router.push({ path: "/" });
    }
  }
};
</script>
